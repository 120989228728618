import React from 'react'
import companyInfo from '../company.json'

const TopBar = () => {
    const { email, phone } = companyInfo
    return (
        <div id="block-toolbar">
            <div className="wrapper">
                <div id="toolbar" className="clearfix">
                    <div className="float-right">
                        <div className="module deepest">
                            <div>Email: <a href={`mailto:${email}`}>{email}</a> | Phone: {phone}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopBar