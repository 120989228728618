import React from 'react'

const ServiceItem = ({title, icon, text}) => {
    return (
        <div className="teaser-item">
            <div className="teaser-item-bg">
                <h1 className="pos-title">{title}</h1>
                <div className="floatbox">
                    <div className="pos-media media-left">
                        <div className="element element-image first last">
                            <img src={icon} alt={title} width="100" height="100"/>
                        </div>
                    </div>
                    <div className="pos-content">
                        <div className="element element-textarea first last">
                            <div><p>{text}</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceItem