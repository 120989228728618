import React from 'react'
import { icon07 } from '../assets/images/icons'
import { country01, country02 } from '../assets/images/countries'

const AboutUs = () => {
    return (
        <div>
            <section id="content" className="grid-block">
                <div id="system" className="yoo-zoo blog-warp6 blog-warp6-frontpage">
                    <div className="items items-col-1 grid-block">
                        <div className="grid-box width100">
                            <article className="item no-date">
                                <header>
                                    <h1 className="title"> Presentation </h1>
                                </header>
                                <div className="content clearfix">
                                    <div className="pos-media align-left">
                                        <div className="element element-image first last">
                                            <img src={icon07} alt="Presentation" width="200" height="200"/></div>
                                    </div>
                                    <div className="pos-content">
                                        <div className="element element-textarea first last">
                                            <div>
                                                <p>WebSupport Services Inc is a small company based in Escazu, San José, Costa Rica.</p>
                                                <p>WebSupport Services Inc was formed by a group of developers, graphic designers, programmers, server experts and marketing people. The idea was to offer our services in a more down to earth way, without all the protocol often found in bigger IT companies.</p>
                                                <p>The partners are the ones doing the work. We know our customers, because we deal directly with them at the most basic level, and can provide fast responsive adjustments and adaptation.</p>
                                                <p>The dream we all shared as a group of friends who just wanted to make a comfortable living using our various trades, we now share as partners of a team with offices in San José, Costa Rica and Panama City, Panama.</p>
                                                <p>Our international background also allows us to offer our customers with multilingual support. We currently offer English, French, Spanish, Italian as basic languages, with German, Vietnamese, Portuguese, Chinese (traditional and simplified) outsourced but available.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            <section id="bottom-a" className="grid-block">
                <div className="grid-box width50 grid-h">
                    <div className="module mod-line deepest" style={{minHeight: '137px'}}>
                        <h3 className="module-title">Republic<span className="color"> of Panama</span></h3>
                        <p><img src={country01} alt="" height={137}/></p>
                    </div>
                </div>
                <div className="grid-box width50 grid-h">
                    <div className="module mod-line deepest" style={{minHeight: '137px'}}>
                        <h3 className="module-title">Costa<span className="color"> Rica</span></h3>
                        <p><img src={country02} alt="" height={137}/></p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default AboutUs