import React from 'react'
import { NavLink } from 'react-router-dom'

const Menu = () => {
    return (
        <nav id="menu">
            <ul className="menu menu-dropdown">
                <li className="level1 item101">
                    <NavLink to="/" exact className="level1" activeClassName="active"><span>Home</span></NavLink>
                </li>
                <li className="level1 item103">
                    <NavLink to="/services" className="level1" activeClassName="active"><span>Services</span></NavLink>
                </li>
                <li className="level1 item104">
                    <NavLink to="/about-us" className="level1" activeClassName="active"><span>About Us</span></NavLink>
                </li>
                <li className="level1 item105">
                    <NavLink to="/contacts" className="level1" activeClassName="active"><span>Contacts</span></NavLink>
                </li>
            </ul>
        </nav>
    )
}

export default Menu